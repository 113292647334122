import {createApp, ref} from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
export const currentUser = ref({
    uuid:'',
    questionList:[],
    answerList:[],
    passed1:false,
    passed2:false,
    passed3:false,
});
const app = createApp(App)
app.use(router).use(ElementPlus).mount('#app')
